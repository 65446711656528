import React from 'react';
import PageHeader from '../components/content/PageHeader';
import Layout from '../modules/Layout';
import Text from '../components/content/Text';
import ContentIconTeaser from '../components/content/IconTeaser';
import ContentTeaser from '../components/content/ContentTeaser';
import { beruf, hausratSliderItem, KpiItems, risiko, sliderItems, unfall } from '.';
import ContentSlider from '../components/content/ContentSlider';
import Kpi from '../components/content/Kpi';
import FamilyCheck from '../components/content/FamilyCheck';

const iconTeserItems = [
  {
    text: 'Schützt Sie, wenn Sie jemanden aus Versehen schädigen',
  },
  {
    text: 'Individueller und bedarfsgerechter Versicherungsschutz bis zu 50 Mio. €',
  },
  {
    text: 'Prüfung der Haftpflichtfrage und ggf. Abwehren von Ansprüchen auch vor Gericht',
  },
];

const contentTeaserItems = [beruf, risiko, unfall];

const Haftpflicht = () => (
  <Layout
    pageName="IIIᐅ Familienversicherung | VGH"
    description="VGH Versicherungscheck online ✅ Welche Versicherungen brauchen Familien? ✅ Wie schützen Sie Ihre Familie am besten? ᐅ Jetzt informieren! "
  >
    <PageHeader
      desktopImage="headerHaftpflicht"
      mobileImage="headerHaftpflicht_mob"
      badge1Line1="Nachbars Scheibe"
      badge1Line2="zu Bruch geht,"
      badge2Line1="sollte das gute Verhältnis"
      badge2Line2="keinen Schaden nehmen"
    />
    <Text
      textAboveHeadline="Privat-Haftpflichtversicherung"
      headline="Erwachsen werden soll schließlich Spaß machen"
      text="<p>Sie haben ganz entspannte Nachbarn, die Kinder lieben? Aber schon mal darüber nachgedacht, wie entspannt sie reagieren, wenn ihr Eigentum beschädigt wird? Ersparen Sie sich den Ärger. Eine Privat-Haftpflichtversicherung beruhigt die Nachbarn – und Sie auch.</p>"
    />
    <ContentIconTeaser
      items={iconTeserItems}
      btnTarget="https://www.vgh.de/content/privat/versicherungen/haftpflicht-und-rechtsschutz/privat/abschluss/index.html#"
      btnText="Jetzt berechnen"
    />
    <FamilyCheck
      headline="Wie viel Sicherheit braucht Ihre Familie?"
      text="Klar wissen Sie, dass Sicherheit absolut wichtig ist und Versicherungen
      sein müssen. Aber welche? Und vor allem, welche passen wirklich zu Ihnen
      und Ihrer Lebenssituation? Klicken Sie sich einfach kurz durch unseren Familien-Check. Wir zeigen Ihnen dann im
      Anschluss direkt die Versicherungen, die Sie auf jeden Fall haben sollten."
    />
    <ContentTeaser
      headline="Unsere stärksten Versicherungen für Sie und die Kids"
      text="Neben unserer Privat-Haftpflichtversicherung sind auch diese drei Versicherungen wichtig, um gut abgesichert in Ihre Familienzukunft zu starten. Stiftung Warentest empfiehlt unsere stärksten Versicherungen für junge Familien ebenfalls."
      items={contentTeaserItems}
    />
    <Text
      headline="Wenn Sie für Ihre Familie auf Nummer sicher gehen wollen"
      text="Noch mehr Versicherungen? Reichen nicht zwei oder drei? Das entscheiden natürlich Sie und hängt zudem von Ihrer Lebenssituation ab. Aber schauen Sie einfach mal durch. Wir haben für alle Ansprüche die passende Versicherung – und passen uns auch Ihren individuellen Wünschen an."
    />
    <ContentSlider items={[hausratSliderItem, ...sliderItems]} />
    <Kpi
      headline="Warum VGH?"
      kpiItems={KpiItems}
      btnTarget="https://www.vgh.de/content/privat/service/betreuersuche/"
      btnText="Berater finden"
    />
  </Layout>
);

export default Haftpflicht;
